import './styles.scss';
import React, {memo, useState, useEffect} from 'react';
import copy from 'copy-to-clipboard';
import {toast, ToastContainer} from "react-toastify";

import {
    BigLogoImage,
    dataroomIcon4,
    dataroomIcon3,
    dataroomIcon2,
    dataroomIcon1,
    share
} from "../../images";
import { Link } from 'gatsby';

type Props = {
    activeTab: Number
}

const HeaderDataRoom = ({activeTab}: Props) => {

    const divRef = React.useRef<HTMLUListElement>(null);

    const [width, setWidth] = useState<number>(0);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        if(width < 768){
            const element = document.querySelector(`.dataRoomNavItem-${activeTab}`);
            divRef.current.scrollLeft = element.offsetLeft;
        }
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    

    const tabs = [
        {
            id: 1,
            img: dataroomIcon4,
            name: "Dashboard",
            link: "/dataroom"
        },
        // {
        //     id: 2,
        //     img: dataroomIcon2,
        //     name: "Road map",
        //     link: "/dataroom-roadmap"
        // },
        // {
        //     id: 3,
        //     img: dataroomIcon3,
        //     name: "About FeelGrow",
        //     link: "/dataroom-about"
        // },
        // {
        //     id: 4,
        //     img: dataroomIcon1,
        //     name: "Business plan",
        //     link: "/dataroom-business-plan/overview"
        // }
    ];

    const copyToClip = () => {
        copy('https://aeer-platform.com/dataroom');
        toast('Copied to clipboard', {
            autoClose: 2000,
            position: "top-center",
            type: 'info',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        })
    }

    const renderTabs = () => {
        return tabs.map((data) => {
            return (
                <li className={`dataRoomNavItem dataRoomNavItem-${data.id}`} key={`item-${data.id}`}>
                    <Link to={data.link} className={`${(activeTab === data.id) && 'active'}`}>
                        <img src={data.img} alt="" />
                        <span>{data.name}</span>
                    </Link>
                </li>
            )
        })
    }

    return (
        <header className="dataRoomHeader">
            <div className="dataRoomContainer dataRoomHeaderContainer">
                <a href="" className="dataRoomLogo">
                    <img src={BigLogoImage} alt="" />
                </a>
                <nav className="dataRoomNav" ref={divRef}>
                    <ul className="dataRoomNavCol" >
                        {
                            renderTabs()
                        }
                        <li className="dataRoomNavItem">
                            <a href="https://drive.google.com/drive/folders/1iW2ZUyoLIykl7LOvTBl94HAPCGjjT8G5?usp=sharing" target="_blank">
                                <img src={dataroomIcon1} alt="" />
                                <span>Legal & Financials</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <a className="dataRoomHeaderMore" onClick={() => copyToClip()}>
                    <img src={share} alt="" />
                </a>
            </div>

            <ToastContainer/>
        </header>
    )
}

export default memo(HeaderDataRoom);